// Custom DatePicker that uses Day.js instead of Moment.js
import { Container, View } from '@/components'
import styled from 'styled-components'

import { PreviewDialogProvider } from '@/components/Search/PreviewDialogProvider'
import { AuthInfo } from '@/lib/auth/AuthStore'
import { LandingPage } from '@/modules/landings/home/LandingPage'
import { NextComponentType } from 'next'
import media from 'styled-media-query'
import { AppRootContext } from '../context'

const HomePageWrapper = styled(View)`
	min-height: 80vh;
	padding-bottom: 0;

	&.header-fixed {
		padding-top: 85px;
	}

	${media.greaterThan('large')`
	 	/* screen width is greater than 1200px (large) */
	`}
`

const RequireKaikasWrppaer = styled.div`
	padding: 120px 20px;
	text-align: center;

	p {
		font-size: 16px;
	}
`

const HomePage: NextComponentType<AppRootContext, {}, { auth: AuthInfo }> = ({ auth }) => {
	return (
		<HomePageWrapper>
			<Container>
				<PreviewDialogProvider>
					<LandingPage />
				</PreviewDialogProvider>
			</Container>
		</HomePageWrapper>
	)
}
//
// HomePage.getInitialProps = async (ctx) => {
// 	const { auth, relayerInfos } = ctx
//
// 	console.log('getInitialProps', auth)
//
// 	return {}
// }

// export default withAuth(HomePage, {
// 	auth: false,
// })

export default HomePage
