import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { Button, Modal } from 'antd'

import Link from 'next/link'

import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { useRouter } from 'next/router'
import { EXTERNAL_CAHNNEL_TALK_BOT, EXTERNAL_PROMOTION_AUTHOR_PAGE } from '@/settings/constant'
import { itemDTO } from '../../api/main'
import Icon from '../icon/Icon'
import { Bell, Sizes } from '../icon/IconList'
export interface EmptyRankingListProps {
	selectedBook: itemDTO
}

export const EmptyHandoutSection: React.FC<EmptyRankingListProps> = ({ selectedBook }) => {
	const { isLoggedIn, user } = useAuthStore()
	const router = useRouter()
	return (
		<EmptyRankingListWrapper>
			<div className={'thumb-wrapper'}>
				<Image
					unoptimized={true}
					alt={'selected-book-image'}
					width={92}
					height={128}
					src={selectedBook.imageUrl}
				/>
			</div>

			<p className={'title'}>
				<strong>{selectedBook.title}</strong>의 <br />
				준비된 수업자료가 아직은 없어요...😥
			</p>
			<Button
				type={'primary'}
				onClick={() => {
					if (isLoggedIn) {
						mixpanelEvent('click_alert', {
							bookId: selectedBook.id,
							userId: user.id,
						})
						Modal.success({
							title: '자료 알림 신청 완료',
							content: (
								<div>
									해당 교재의 수업자료가 업로드되면 고객님께 문자 혹은 알림톡을
									통해 알려드릴게요
								</div>
							),
							okText: '확인',
						})
						return
					}
					Modal.confirm({
						title: '자료 알림 신청은 로그인이 필요해요',
						content: (
							<div>수업자료의 업로드를 알리기 위해서 로그인 정보가 필요해요</div>
						),
						okText: '로그인',
						onOk: () => {
							router.push('/login')
						},
						cancelText: '닫기',
					})
				}}>
				<Icon icon={Bell} color={'#fff'} size={Sizes.xmedium} />
				자료 알림 받기
			</Button>
			<div className={'link-wrapper'}>
				<Link href={EXTERNAL_CAHNNEL_TALK_BOT} target={'_blank'}>
					<div>
						<p>
							필요한 유형의 수업자료가 <br />
							있으신가요?
						</p>
						<p>수업자료 신청하기 </p>
					</div>
					<Image
						unoptimized={true}
						alt={'link-img'}
						width={100}
						height={100}
						src={'/static/assets/empty_mail_box.svg'}
					/>
				</Link>
				<Link href={EXTERNAL_PROMOTION_AUTHOR_PAGE} target={'_blank'}>
					<div>
						<p>
							내가 제작한 수업자료로 <br />월 200만원
						</p>
						<p>실제 사례 살펴보기</p>
					</div>
					<Image
						unoptimized={true}
						alt={'link-img'}
						width={100}
						height={100}
						src={'/static/assets/empty_document_money.svg'}
					/>
				</Link>
			</div>
		</EmptyRankingListWrapper>
	)
}
const EmptyRankingListWrapper = styled.section`
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	padding: 40px 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	margin-bottom: 72px;
	.thumb-wrapper {
		width: 200px;
		height: 200px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
		}
	}
	.title {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		strong {
			color: ${(props) => props.theme.new.colors.sol_indigo_500};
			font-weight: 700;
		}
	}
	.ant-btn {
		height: 48px;
		width: 200px;
		border-radius: 6px;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			margin-right: 8px;
		}
	}
	.link-wrapper {
		display: flex;
		gap: 20px;
		margin-top: 40px;
		a {
			border-radius: 6px;
			background-color: #fff;
			padding: 20px;
			display: flex;
			position: relative;
			height: 136px;
			justify-content: space-between;
			width: 360px;
			img {
				position: absolute;
				right: 0;
				bottom: 0;
				padding: 16px;
				height: 100px;
			}
			div {
				flex-direction: column;
				justify-content: space-between;
				display: flex;
			}
			p:first-child {
				font-size: 18px;
				font-weight: 700;
				line-height: 26px;
			}
			p:last-child {
				color: ${(props) => props.theme.new.colors.sol_indigo_500};
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
			}
		}
	}
	@media (max-width: 768px) {
		.thumb-wrapper {
			width: 158px;
			height: 158px;
			img {
				width: 72px;
			}
		}
		.title {
			font-size: 20px;
		}
		.link-wrapper {
			flex-direction: column;
		}
	}
`
