import { toQueryStringCamel } from 'src/utils/misc'
import { otherApiClient } from '.'
import { Categories, Subjects } from './product'

export interface itemDTO {
	id: string | number
	imageUrl?: string
	title: string
	type?: 'textbook' | 'workbook'
}

export interface BannerItemDTO {
	imageUrlMobile: string
	imageUrlPc: string
	imageUrlTablet: string
	link: string
}

export interface RankingItemDTO extends itemDTO {
	author: string
	category: {
		tag: string
		plus: number
	}
	isEditable: boolean
	isCouponAvailable?: boolean
	labName: string
	price: number
	unit: string
	rating?: number
	subject?: Subjects
}

export interface CurationSectionType {
	title: string
	id: string
	items: RankingItemDTO[]
}

export interface IMainRes {
	// 메인 배너
	mainBanner: {
		items: BannerItemDTO[]
		count: number
	}
	//선택과목
	mainSubject: Subjects
	userSourcePanel: {
		items: itemDTO[]
	}
	//맞춤교재 종속데이터
	userSourceData: {
		allProduct: {
			items: RankingItemDTO[]
		}
		//수동 큐레이션
		curation: {
			sections: Array<CurationSectionType>
		}
		curationAll: {
			sections: Array<CurationSectionType>
		}
		//empty ui
		isEmpty: boolean
		//미니배너
		miniBanner: {
			items: {
				upperText: string
				mainText: string
				link: string
				imageUrl: string
				bgColor: string
			}[]
		}
		//맞춤 랭킹
		salesRanking: {
			items: RankingItemDTO[]
			type: {
				items: itemDTO[]
				selected: Categories
				updateHour: number
			}
		}
		//맞춤교재 리스트
	}
}

export interface IMainReq {
	subject?: Subjects
	userSource?: string
	salesRankingType?: Categories
}

export const getMain = async (req: IMainReq) => {
	const { data } = await otherApiClient.market.get<IMainRes>(`/main/${toQueryStringCamel(req)}`)
	return data
}
