import React from 'react'
import styled, { useTheme } from 'styled-components'
import Icon from '../icon/Icon'
import { ArrowLeft, ArrowRight, Sizes } from '@/components/icon/IconList'

export interface SwiperCustomButtonProps {
	nextClassName: string
	prevClassName: string
}

export const SwiperCustomButton: React.FC<SwiperCustomButtonProps> = ({
	nextClassName,
	prevClassName,
}) => {
	const theme = useTheme()
	return (
		<SwiperCustomButtonWrapper>
			<button className={prevClassName}>
				<Icon icon={ArrowLeft} size={Sizes.small} color={theme.new.colors.sol_gray_900} />
			</button>
			<button className={nextClassName}>
				<Icon icon={ArrowRight} size={Sizes.small} color={theme.new.colors.sol_gray_900} />
			</button>
		</SwiperCustomButtonWrapper>
	)
}
const SwiperCustomButtonWrapper = styled.div`
	display: flex;
	flex: 1;
	gap: 8px;
	float: right;
	justify-content: flex-end;
	button {
		all: unset;
		width: 32px;
		height: 32px;
		border-radius: 2px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&.swiper-button-disabled {
			opacity: 0.4;
		}
		:hover,
		:active {
			background-color: ${(props) => props.theme.new.colors.sol_gray_100};
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
`
