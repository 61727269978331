import React, { useEffect, useRef, useState } from 'react'
import { CurationSectionType, RankingItemDTO } from 'src/api/main'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BookCard } from './BookCard'
import { ManualCurationSkeleton } from './MainPageSkeletons'
import { SwiperCustomButton } from './SwiperCustomButton'
import 'swiper/swiper-bundle.css'

export interface ManualCurationSectionProps {
	showSkeleton: boolean
	data: CurationSectionType
	onClick: (book: RankingItemDTO, title: string) => void
}

export const ManualCurationSection: React.FC<ManualCurationSectionProps> = ({
	data,
	showSkeleton,
	onClick,
}) => {
	const [skeletonTimer, setSkeletonTimer] = useState(false)
	const swiperRef = useRef(null)
	useEffect(() => {
		if (showSkeleton) {
			setSkeletonTimer(true)
			setTimeout(() => {
				setSkeletonTimer(false)
			}, 1000)
		}
	}, [showSkeleton])
	if (skeletonTimer) {
		return <ManualCurationSkeleton />
	}
	if (!data) return <></>
	return (
		<>
			<ManualCurationSectionWrapper key={`manual-curation-${data.title}`}>
				<div className={'header'}>
					<SectionTitle>{data.title}</SectionTitle>
					<SwiperCustomButton
						nextClassName={`manual-curation-button-next-${data.id}`}
						prevClassName={`manual-curation-button-prev-${data.id}`}
					/>
				</div>
				<Swiper
					ref={swiperRef}
					height={382}
					// width={224}
					scrollbar={false}
					slidesPerView="auto"
					spaceBetween={20}
					navigation={{
						nextEl: `.manual-curation-button-next-${data.id}`,
						prevEl: `.manual-curation-button-prev-${data.id}`,
					}}>
					{data.items.map((book, index) => {
						return (
							<SwiperSlide
								key={`item-${index}`}
								onClick={() => onClick(book, data.title)}>
								<BookCard
									book={book}
									key={`manual-curation-book-info-${index}`}
									index={index}
									positionType={'curation'}
								/>
							</SwiperSlide>
						)
					})}
				</Swiper>
			</ManualCurationSectionWrapper>
		</>
	)
}

const ManualCurationSectionWrapper = styled.section`
	margin: 72px 0;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.book-info-wrapper {
		width: 224px;
		flex-direction: column;
		gap: 8px;

		img {
			width: 92px;
		}

		.thumb-wrapper {
			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			width: 100%;
			padding: 8px;
			height: 224px;
			padding-bottom: 40px;
		}

		> div {
			position: relative;
		}

		.preview-btn {
			bottom: 8px;
			left: 8px;
			right: 8px;
			width: auto;
			text-align: center;
		}
	}

	.swiper-slide {
		width: unset;
		height: unset;
	}

	@media (max-width: 1200px) {
		margin-right: -32px;
	}
	@media (max-width: 768px) {
		margin: 32px 0;
		.swiper {
			margin-right: -16px;
		}

		.book-info-wrapper {
			width: 150px;

			.thumb-wrapper {
				height: 150px;
				padding-bottom: 8px;

				img {
					width: 68px;
				}
			}
		}

		.preview-btn {
			display: none;
		}
	}
`
export const SectionTitle = styled.h3`
	color: ${(props) => props.theme.new.colors.sol_gray_800};
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	margin-bottom: 20px;

	span {
		margin-left: 8px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	@media (max-width: 768px) {
		font-size: 20px;
		span {
			font-size: 14px;
		}
	}
`
