import Icon from '@/components/icon/Icon'
import { ArrowDown, Plus, Setting, Sizes } from '@/components/icon/IconList'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import Select from '@/components/common/Select'
import { Button } from 'antd'

import { GuidTooltip } from '@/components/tooltip/GuidTooltip'
import { itemDTO } from 'src/api/main'
import { EditSelectBookBtn } from './SelectedBookList'
import { GNBHeight, GNBHeightMobile } from '@/containers/Header/GNB.style'

export interface SelectedBooksDropDownProps {
	openSelectBooksModal: () => void
	selectedBook: itemDTO
	setSelectedBook: (book: itemDTO) => void
	selectBookList: itemDTO[]
	isOpenDropdown: boolean
	setIsOpenDropdown: (isOpen: boolean) => void
	guidTooltipOpen: boolean
	setGuidTooltipOpen: (isOpen: boolean) => void
	setModalType: (ModalType) => void
}

export const SelectedBooksDropDown: React.FC<SelectedBooksDropDownProps> = ({
	openSelectBooksModal,
	selectBookList,
	selectedBook,
	setSelectedBook,
	isOpenDropdown,
	setIsOpenDropdown,
	guidTooltipOpen,
	setGuidTooltipOpen,
	setModalType,
}) => {
	const theme = useTheme()
	const selected = () => {
		if (selectedBook) {
			return {
				label: selectedBook.title,
				image: selectedBook.imageUrl,
				value: selectedBook.id.toString(),
			}
		} else {
			return {
				label: '전체',
				image: '',
				value: '',
			}
		}
	}

	return (
		<SelectedBooksDropDownWrapper>
			<div
				onClick={() => {
					setGuidTooltipOpen(false)
					if (!selectedBook) {
						setModalType('selectBooksModal')
						openSelectBooksModal()
					}
				}}>
				{selectBookList.length === 0 && (
					<GuidTooltip isVisibleTooltip={guidTooltipOpen} placement={'left'}>
						내게 필요한 교재의 자료만 골라보기!
					</GuidTooltip>
				)}
				<Select
					disabled={selectBookList.length === 0}
					isOpen={isOpenDropdown}
					setIsOpen={setIsOpenDropdown}
					selected={selected()}
					onChange={(option) =>
						setSelectedBook({
							title: option.label,
							imageUrl: option.image,
							id: option.value,
						})
					}
					options={selectBookList.map((book) => ({
						label: book.title,
						image: book.imageUrl,
						value: book.id.toString(),
					}))}
					placeholder={'placeholder'}
					defaultValue={'전체'}
					className={'selectBooks-dropdown'}
					dropDownChildren={
						<>
							<Button
								className={'add-books-button'}
								type={'primary'}
								onClick={openSelectBooksModal}>
								<Icon
									icon={Plus}
									size={Sizes.xmedium}
									color={theme.new.colors.sol_indigo_600}></Icon>
								추가하기
							</Button>
						</>
					}
					suffix={
						<div className={'suffix-icon'}>
							<Icon
								icon={ArrowDown}
								size={Sizes.xxsmall}
								color={theme.new.colors.sol_gray_900}
							/>
						</div>
					}
				/>

				<EditSelectBookBtn
					className={'edit-select-book-btn'}
					onClick={openSelectBooksModal}>
					<Icon icon={Setting} size={Sizes.small} color={theme.new.colors.sol_gray_700} />
					교재 설정
				</EditSelectBookBtn>
			</div>
		</SelectedBooksDropDownWrapper>
	)
}

export const SelectedBooksDropDownWrapper = styled.div`
	position: sticky;
	top: ${GNBHeight}px;
	z-index: 2;
	background-color: #fff;
	padding: 8px 32px;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_50};
	width: 100vw;
	margin-left: calc(50% - 50vw);

	> div {
		max-width: 1200px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		:first-child {
			position: relative;

			.guid-tooltip-wrapper {
				//left: unset;
				left: 200px;
			}
		}
	}

	.selectBooks-dropdown {
		flex: 0 0 auto;
		align-items: center;
		padding: 4px 0px;
		border: transparent;
		border-radius: 4px;

		> p {
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			@media (max-width: 768px) {
				font-size: 14px;
				line-height: 22px; /* 157.143% */
				max-width: 250px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		&.open {
			//background-color: ${(props) => props.theme.new.colors.sol_gray_100};

			.suffix-icon {
				//background-color: #fff;
			}
		}

		.options {
			width: max-content;
		}

		ul {
			padding: 12px 12px;
			overflow-y: auto;
			max-height: 400px;
			overscroll-behavior: contain;
			gap: 8px;
			flex-direction: column;
			margin-top: unset;
			top: 54px;

			::-webkit-scrollbar {
				width: 4px;
			}

			::-webkit-scrollbar-thumb {
				background-color: ${(props) => props.theme.new.colors.sol_gray_300};
				border-radius: 2px;
			}

			&.open {
				display: flex;
			}
		}

		li {
			max-width: 416px;
			border-radius: 6px;
			display: flex;
			align-items: center;
			-webkit-line-clamp: 2;
			white-space: normal;
			//overflow: hidden;
			text-overflow: ellipsis;
			//display: -webkit-box;
			-webkit-box-orient: vertical;

			img {
				margin-right: 16px;
				border-radius: 2px;
				box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
			}

			&.selected {
				background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
				color: ${(props) => props.theme.new.colors.sol_indigo_500};
				font-weight: 600;

				> div {
					color: ${(props) => props.theme.new.colors.sol_indigo_500};
				}
			}
		}
	}

	.suffix-icon {
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		border-radius: 50%;
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 4px;
	}

	.add-books-button {
		height: 48px;
		padding: 12px;
		border-radius: 6px;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_100};
		color: ${(props) => props.theme.new.colors.sol_indigo_600};
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		> i {
			margin-right: 8px;
		}

		:hover {
			background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
			color: ${(props) => props.theme.new.colors.sol_indigo_600};
		}
	}

	@media (max-width: 768px) {
		top: ${GNBHeightMobile}px;
		width: calc(100% + 32px);
		margin: 0 -16px;
		padding: 8px 16px;
		ul {
			max-height: unset;
		}

		.edit-select-book-btn {
			margin-right: 0;
		}
	}
`
