import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'
import Icon from '@/components/icon/Icon'
import { ArrowRight, Sizes } from '@/components/icon/IconList'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { SwiperOptions } from 'swiper'

export interface MiniBannerSectionProps {
	data: {
		items: {
			upperText: string
			mainText: string
			imageUrl: string
			bgColor: string
			link: string
		}[]
	}
	onClick?: (banner: unknown, bannerType: string) => void
}

export const MiniBannerSection: React.FC<MiniBannerSectionProps> = ({ data, onClick }) => {
	interface ISlideOptions
		extends Pick<
			SwiperOptions,
			'slidesPerView' | 'slidesPerGroup' | 'loop' | 'autoplay' | 'scrollbar'
		> {}
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const initialSlideOptions = {
		slidesPerView: 3,
		spaceBetween: 20,
	}
	const [slideOptions, setSlideOptions] = useState<ISlideOptions>(initialSlideOptions)

	const swiperRef = useRef(null)
	useEffect(() => {
		if (isMobile) {
			setSlideOptions({
				slidesPerView: 1,
				slidesPerGroup: 1,
				autoplay: { delay: 4000 },
			})
			swiperRef.current?.swiper?.update()
			swiperRef.current?.swiper?.autoplay.start()
		} else setSlideOptions(initialSlideOptions)
	}, [width])
	if (!data) {
		return
	}
	return (
		<MiniBannerSectionWrapper>
			<Swiper ref={swiperRef} {...slideOptions} className={'best-sales-swiper'}>
				{data.items.map((item, index) => {
					return (
						<SwiperSlide key={`best-sales-swiper-${index}`}>
							<MiniBannerLinkWrapper
								onClick={() => onClick(item, 'miniBanner')}
								href={item.link}
								style={{ backgroundColor: item.bgColor }}>
								<Image
									src={item.imageUrl}
									alt={item.mainText}
									width={64}
									height={64}
								/>
								<div>
									<p>{item.upperText}</p>
									<p>
										{item.mainText}{' '}
										<Icon icon={ArrowRight} size={Sizes.small} />
									</p>
								</div>
							</MiniBannerLinkWrapper>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</MiniBannerSectionWrapper>
	)
}
const MiniBannerSectionWrapper = styled.div`
	.swiper-slide:last-child {
		margin-right: 0 !important;
	}

	@media (max-width: 1200px) {
		.swiper-slide {
			//flex-shrink: unset;
		}
	}
`
const MiniBannerLinkWrapper = styled(Link)`
	height: 104px;
	border-radius: 6px;
	padding: 20px 24px;
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	cursor: pointer;
	box-sizing: border-box;
	border: 1px solid transparent;
	> div {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		p:first-child {
			color: ${(props) => props.theme.new.colors.gray500};
			line-height: 24px;
		}
		p:last-child {
			font-size: 20px;
			font-weight: 600;
			line-height: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			i {
				margin-left: 4px;
			}
		}
	}
	:hover {
		border: 1px solid ${(props) => props.theme.new.colors.gray300};
	}
	@media (hover: hover) {
		:hover {
			border: 1px solid ${(props) => props.theme.new.colors.gray300};
		}
	}
	@media (max-width: 1200px) {
		//min-width: 320px;
		width: 100%;
		padding: 16px 20px;
		height: 86px;
	}
	@media (max-width: 1100px) {
		img {
			display: none;
		}
	}
	@media (max-width: 768px) {
		height: 74px;
		//width: 280px;
		padding: 16px 20px;
		img {
			width: 45px;
			display: block;
		}
		> div {
			p:first-child {
				font-size: 12px;
				line-height: 18px;
			}
			p:last-child {
				font-size: 18px;
				line-height: 26px;
			}
		}
	}
`
