import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/scrollbar'
import { Categories, Subjects } from 'src/api/product'
import { itemDTO, RankingItemDTO } from 'src/api/main'
import { RankingListSkeleton } from './MainPageSkeletons'
import { BookCard } from './BookCard'
import { SwiperCustomButton } from './SwiperCustomButton'

// SwiperCore.use([Grid, Pagination, Scrollbar])

export interface AllHandoutListSectionProps {
	selectedDataType: Categories
	handleDataType: (key: Categories) => void
	selectedBook: itemDTO
	showSkeleton: boolean
	isEmptyRanking: boolean
	subject: Subjects
	data: {
		items: RankingItemDTO[]
	}
}

export const AllHandoutSection: React.FC<AllHandoutListSectionProps> = ({
	handleDataType,
	data,
	selectedDataType,
	showSkeleton,
	isEmptyRanking,
	selectedBook,
	subject,
}) => {
	const theme = useTheme()
	const [skeletonTimer, setSkeletonTimer] = useState(null)
	const swiperOptions = {
		slidesPerView: 'auto' as 'auto' | number,
		centerInsufficientSlides: true,
		navigation: {
			nextEl: '.all-handout-button-next',
			prevEl: '.all-handout-button-prev',
		},
		spaceBetween: 20,
		// breakpoints: {
		// 	1200: {
		// 		grid: {
		// 			rows: 2,
		// 		},
		// 	},
		// 	0: {
		// 		grid: {
		// 			rows: 1,
		// 		},
		// 	},
		// },
	}
	useEffect(() => {
		if (showSkeleton) {
			setSkeletonTimer(
				setTimeout(() => {
					setSkeletonTimer(null)
				}, 1000),
			)
		}
	}, [showSkeleton])

	if (skeletonTimer) {
		return <RankingListSkeleton />
	}
	if (!!!data || data.items.length === 0) {
		return <></>
	}

	return (
		<AllHandoutListSectionWrapper>
			<ListHeader>
				<SectionTitle>👀 가장 최근에 업로드된 자료</SectionTitle>
				{subject === Subjects.EN ? (
					<Link href={`/search?q=${selectedBook?.title}&subject=${subject}&sort=LATEST`}>
						전체보기
					</Link>
				) : (
					<SwiperCustomButton
						nextClassName={`all-handout-button-next`}
						prevClassName={`all-handout-button-prev`}
					/>
				)}
			</ListHeader>
			<ProductList>
				<Swiper {...swiperOptions}>
					{data.items.map((book, index) => {
						return (
							<SwiperSlide key={`book-slide-${index}`}>
								<BookCard book={book} index={index} positionType={'curation'} />
							</SwiperSlide>
						)
					})}
				</Swiper>
			</ProductList>
		</AllHandoutListSectionWrapper>
	)
}
const SwiperPaddingW = 36 * 2

const AllHandoutListSectionWrapper = styled.div`
	margin-top: 52px;
`
const ListHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
	}
`

const ProductList = styled.div`
	//height: 784px;

	.swiper {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		//임시 변경필요
		padding-right: ${SwiperPaddingW}px;
	}

	.swiper-wrapper {
		//row-gap: 20px;
		//column-gap: 20px;
		align-content: flex-start;
		@media (max-width: 768px) {
			//row-gap: 20px;
			//column-gap: 20px;
		}
	}

	.swiper-slide {
		text-align: center;
		//flex-shrink: unset;
		width: unset !important;
		height: unset;
		flex: 1 1 auto;
		//border: 1px solid red;

		//display: flex;
		//justify-content: center;
		//align-items: center;
		:nth-child(1),
		:nth-child(2),
		:nth-child(3) {
			.index-label {
				background-color: ${(props) => props.theme.new.colors.sol_blue_500};
			}
		}
	}

	.swiper {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.book-info-wrapper {
		width: 224px;
		flex-direction: column;
		gap: 8px;

		img {
			width: 92px;
		}

		.thumb-wrapper {
			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			width: 100%;
			padding: 8px;
			height: 224px;
			padding-bottom: 40px;
		}

		> div {
			position: relative;
		}

		.preview-btn {
			bottom: 8px;
			left: 8px;
			right: 8px;
			width: auto;
			text-align: center;
		}
	}

	@media (max-width: 1200px) {
		height: 382px;
		margin-right: -32px;
	}
	@media (max-width: 768px) {
		//margin: 32px 0;
		margin-right: -16px;
		margin-bottom: 32px;
		height: 306px;
		.book-info-wrapper {
			width: 150px;

			.thumb-wrapper {
				height: 150px;
				padding-bottom: 8px;

				img {
					width: 68px;
				}
			}
		}

		.preview-btn {
			display: none;
		}
	}
`
export const SectionTitle = styled.h3`
	color: ${(props) => props.theme.new.colors.sol_gray_800};
	font-size: 24px;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 20px;

	span {
		margin-left: 8px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	@media (max-width: 768px) {
		margin-bottom: 16px;
		font-size: 20px;

		span {
			font-size: 14px;
		}
	}
`
