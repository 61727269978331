import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Button } from '@bookips/solvook-ui-library'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RankingItemDTO, itemDTO } from 'src/api/main'
import { Categories } from 'src/api/product'
import styled, { useTheme } from 'styled-components'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/scrollbar'
import Icon from '../icon/Icon'
import { ArrowDown, Sizes } from '../icon/IconList'
import { BookCard } from './BookCard'
import { EmptyHandoutSection } from './EmptyHandoutSection'
import { RankingListSkeleton } from './MainPageSkeletons'

interface RankingListSectionProps {
	selectedDataType: Categories
	handleDataType: (key: Categories) => void
	selectedBook: itemDTO
	showSkeleton: boolean
	isEmptyRanking: boolean
	data: {
		items: RankingItemDTO[]
		type: {
			items: itemDTO[]
			selected: Categories
			updateHour: number
		}
	}
	onClick: (book: RankingItemDTO, title: string) => void
}

export const RankingSection: React.FC<RankingListSectionProps> = ({
	handleDataType,
	data,
	selectedDataType,
	showSkeleton,
	isEmptyRanking,
	selectedBook,
	onClick,
}) => {
	const [skeletonTimer, setSkeletonTimer] = useState(null)
	const { isMobile } = useWindowDimensions()

	useEffect(() => {
		if (showSkeleton) {
			setSkeletonTimer(
				setTimeout(() => {
					setSkeletonTimer(null)
				}, 1000),
			)
		}
	}, [showSkeleton])

	if (skeletonTimer) {
		return <RankingListSkeleton />
	}
	if (selectedBook && isEmptyRanking) {
		return <EmptyHandoutSection selectedBook={selectedBook} />
	}
	if (data === null) return <></>

	return (
		<GridCarouselWrapper>
			<SectionTitle>
				👑 자료 유형별 판매 랭킹
				<span>매일 자정 업데이트</span>
			</SectionTitle>
			<div className={'button-wrapper'}>
				<DataTypeButton>
					{data.type.items.map((item, index) => {
						return (
							<button
								key={`data-type-button-${index}`}
								onClick={() => handleDataType(item.id as Categories)}
								className={selectedDataType === item.id ? 'active' : ''}>
								{item.title}
							</button>
						)
					})}
				</DataTypeButton>
			</div>

			<CarouselItem>
				{isMobile ? (
					<RankingMoreList rankingList={data} />
				) : (
					<BookBox>
						{data.items.slice(0, 12).map((book, index) => {
							return (
								<div
									className="card-wrapper"
									key={`book-slide-${index}`}
									onClick={() => onClick(book, 'rankingProduct')}>
									<BookCard
										book={book}
										index={index}
										positionType={'selectedBook'}
									/>
								</div>
							)
						})}
					</BookBox>
				)}
			</CarouselItem>
		</GridCarouselWrapper>
	)
}

const RankingMoreList = ({ rankingList }: { rankingList: RankingListSectionProps['data'] }) => {
	const SHOW_MORE_COUNT = 3
	const SHOW_MORE_MAX_COUNT = 12
	const theme = useTheme()

	const [appendCount, setAppendCount] = useState<number>(SHOW_MORE_COUNT)
	const currCount = useRef<number>(SHOW_MORE_COUNT)

	const handleMoreClick = useCallback(() => {
		if (currCount.current < SHOW_MORE_MAX_COUNT) {
			setAppendCount((prev) => prev + SHOW_MORE_COUNT)
		}
	}, [])
	return (
		<>
			<BookBox>
				{rankingList.items.slice(0, appendCount).map((book, index) => {
					return (
						<div
							className="card-wrapper"
							key={`book-slide-${index}`}
							onClick={() => {}}>
							<BookCard book={book} index={index} positionType={'selectedBook'} />
						</div>
					)
				})}
			</BookBox>
			{rankingList.items.length > appendCount && (
				<MoreButton>
					<Button
						fullWidth
						label="랭킹 더보기"
						variant="outlined"
						endIcon={
							<Icon
								icon={ArrowDown}
								size={Sizes.small}
								color={theme.new.colors.sol_gray_500}
							/>
						}
						onClick={() => handleMoreClick()}
					/>
				</MoreButton>
			)}
		</>
	)
}
const SwiperPaddingW = 156
const SwiperPaddingM = 80
const DataTypeButton = styled.div`
	display: flex;
	flex: 1;
	gap: 10px;

	> button {
		padding: 8px 12px;
		border-radius: 6px;
		border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		background-color: #fff;
		font-weight: 600;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		cursor: pointer;

		&.active {
			background-color: ${(props) => props.theme.new.colors.sol_blue_500};
			border: 1px solid transparent;
			color: #fff;
		}
	}

	@media (max-width: 768px) {
		gap: 8px;
		> button {
			padding: 4px 10px;
			font-size: 14px;
			line-height: 22px;
		}
	}
`
const GridCarouselWrapper = styled.section`
	margin-bottom: 72px;

	> .button-wrapper {
		display: flex;
		align-items: flex-end;
		margin-bottom: 20px;
	}

	@media (max-width: 768px) {
		margin-bottom: 34px;
		> .button-wrapper {
			margin-bottom: 16px;
		}
	}
`

const CarouselItem = styled.div`
	/* height: 572px; */
	width: 100%;

	@media (max-width: 1200px) {
		margin-right: -32px;
	}
	@media (max-width: 768px) {
		/* height: 448px; */
		margin-right: -16px;
	}

	.book-info-wrapper {
		height: 172px;
		/* width: 420px; */

		img {
			width: 68px;
		}

		@media (max-width: 768px) {
			height: 136px;
			width: 320px;
			gap: 12px;
			img {
				width: 56px;
			}
		}
	}
`
const BookBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 32px 36px;
	.preview-btn {
		width: 100%;
	}

	.card-wrapper {
		position: relative;

		:nth-child(1),
		:nth-child(2),
		:nth-child(3) {
			.index-label {
				background-color: ${(props) => props.theme.new.colors.sol_blue_400};
				color: #fff;
			}
		}
	}

	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		gap: 20px 20px;
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		gap: 16px;
	}
`
export const SectionTitle = styled.h3`
	color: ${(props) => props.theme.new.colors.sol_gray_800};
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	margin-bottom: 20px;

	span {
		margin-left: 8px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	@media (max-width: 768px) {
		margin-bottom: 12px;
		font-size: 18px;
		span {
			font-size: 12px;
		}
	}
`
const MoreButton = styled.div`
	margin-top: 16px;

	button {
		border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
		color: ${(props) => props.theme.new.colors.sol_gray_500};

		:active {
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		}
		:hover {
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		}
	}
`
