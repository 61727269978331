import React from 'react'
import styled from 'styled-components'
import { Divider, styled as muiStyled } from '@mui/material'
import { GoodnotesPromotion } from './GoodnotesPromotion'
import { PassPromotion } from './PassPromotion'

export const PromotionLinks: React.FC = ({}) => {
	return (
		<PromotionStack>
			<GoodnotesPromotion />
			<ButtonDivider orientation={'vertical'} textAlign={'center'} />
			<PassPromotion />
		</PromotionStack>
	)
}
export const ButtonDivider = muiStyled(Divider)(({ theme }) => {
	return {
		height: '14px',
		margin: '5px 0',
		color: theme.palette.grey['100'],
	}
})
export const PromotionStack = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		display: none;
	}
`
