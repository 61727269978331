import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { BannerItemDTO } from 'src/api/main'
import styled from 'styled-components' // import { Image } from 'antd'
import 'swiper/css/effect-coverflow' // import 'swiper/css/coverflow'/
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

interface MainBannerListSectionProps {
	data: {
		count: number
		items: BannerItemDTO[]
	}
	onClick: (banner: BannerItemDTO, bannerType: string) => void
	subject: string
}

export const MainBannerSection: React.FC<MainBannerListSectionProps> = ({
	data: tempData,
	onClick,
	subject,
}) => {
	if (!tempData) {
		return
	}

	return (
		<Container>
			<PaginationStyledSwiper
				navigation={true}
				scrollbar={false}
				pagination={{ type: 'fraction' }}
				autoplay={{ delay: 4000 }}
				loop={true}>
				{tempData.items.map((banner, idx) => {
					return (
						<SwiperSlide key={`item-${idx}`}>
							<Link
								href={banner.link}
								target={'_blank'}
								onClick={() => onClick(banner, 'mainBanner')}>
								<Image
									width={1200}
									height={210}
									className={'show_w'}
									src={banner.imageUrlPc}
									alt={`${idx}-img`}
								/>
								<Image
									width={1200}
									height={220}
									className={'show_t'}
									// style={contentMobileStyle}
									src={banner.imageUrlTablet}
									alt={`${idx}-img`}
								/>
								<Image
									width={800}
									height={250}
									className={'show_m'}
									// style={contentMobileStyle}
									src={banner.imageUrlMobile}
									alt={`${idx}-img`}
								/>
							</Link>
						</SwiperSlide>
					)
				})}
			</PaginationStyledSwiper>
		</Container>
	)
}

const Container = styled.section`
	margin: 52px 0;

	.swiper {
		border-radius: 6px;
	}

	.show_m {
		display: none;
		flex-shrink: 0;
	}

	.show_t {
		display: none;
		flex-shrink: 0;
	}

	@media (max-width: 1200px) {
		margin: 42px 0;
		.show_w {
			display: none;
		}

		.show_t {
			display: block;
			aspect-ratio: 110/21;
		}
	}
	@media (max-width: 768px) {
		margin: 32px 0;
		.show_m {
			display: block;
			aspect-ratio: 400/125;
			//aspect-ratio: 358/100;
		}

		.show_t {
			display: none;
		}
	}
`
export const paginationWidth = 77
export const PaginationStyledSwiper = styled(Swiper)`
	.swiper-pagination {
		top: unset;
		left: unset;
		right: -15px;
		bottom: 20px;
		padding: 0 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		color: rgba(255, 255, 255, 0.5);
		//height: 32px;
		width: ${paginationWidth}px;
		margin: 0 auto;
		transform: translateX(-50%);
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 16px;

		.swiper-pagination-current {
			color: #fff;
			padding-right: 4px;
		}

		.swiper-pagination-total {
			padding-left: 4px;
		}

		@media (max-width: 768px) {
			bottom: 16px;
			height: 24px;
			right: 24px;
			font-size: 14px;
		}
	}

	.swiper-button-prev {
		left: 20px;
		padding-right: 3px;
	}
	.swiper-button-next {
		padding-left: 3px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		width: 40px;
		height: 40px;
		background: white;
		border-radius: 50%;
		right: 24px;
		//left: 24px;
		::after {
			color: #292e40;
			font-size: 22px;
		}
	}

	:hover {
		transition: all 0.3s ease;

		.swiper-button-prev,
		.swiper-button-next {
			display: flex;
		}
	}

	@media (max-width: 1200px) {
		.swiper-pagination {
			left: unset;
			right: -42px;
			bottom: 0;
		}
		.swiper-button-prev,
		.swiper-button-next {
			display: none !important;
		}
	}
`
