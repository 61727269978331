import { EBOOKS_UNIVERSITY_LANDING } from '@/settings/constant'
import { Button, Checkbox, ConfirmDialog } from '@bookips/solvook-ui-library'
import { Typography, styled, useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

const VISIT_STORAGE_KEY = 'visit-before'

const CheckUniversityDialog = () => {
	const router = useRouter()
	const theme = useTheme()

	const [checked, setChecked] = useState(false)
	const [open, isOpen] = useState(false)

	const VISIT_BEFORE = localStorage.getItem(VISIT_STORAGE_KEY)

	const handleOk = useCallback(() => {
		if (checked) {
			const today = Date.now()
			const expires = new Date(today + 1000 * 60 * 60 * 24)

			localStorage.setItem(VISIT_STORAGE_KEY, expires.getTime().toString())
		}

		isOpen(false)
	}, [checked])

	useEffect(() => {
		if (VISIT_BEFORE === null) {
			return isOpen(true)
		}

		if (Number(VISIT_BEFORE) < Date.now()) {
			return isOpen(true)
		}

		return isOpen(false)
	}, [])
	return (
		<ConfirmDialog
			open={open}
			onClose={() => isOpen(false)}
			title="굿노트 eBook 대학교재를 찾으시나요?"
			content={
				<Typography variant="body1" color={theme.palette.text.secondary}>
					대학교재 홈을 찾고 계신다면 ‘대학교재 홈 가기’를 클릭해주세요.
				</Typography>
			}
			sx={{ padding: '20px', margin: '20px' }}
			footer={
				<FooterRow>
					<Checkbox
						label={
							<Typography variant="body1" color={theme.palette.text.secondary}>
								하루동안 보지 않기
							</Typography>
						}
						value={''}
						checked={checked}
						onChange={() => {
							setChecked((prev) => !prev)
						}}
					/>

					<div>
						<Button
							variant="soft"
							label="닫기"
							onClick={() => {
								handleOk()
							}}
						/>
						<Button
							variant="solid"
							label="대학교재 홈 가기"
							onClick={() => {
								handleOk()
								router.push(EBOOKS_UNIVERSITY_LANDING)
							}}
						/>
					</div>
				</FooterRow>
			}
		/>
	)
}

const FooterRow = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: '28px',

	div: {
		display: 'flex',
		gap: '8px',
	},

	button: {
		padding: '12px 20px',
	},

	'@media (max-width: 768px)': {
		flexDirection: 'column',
		alignItems: 'flex-start',

		div: {
			width: '100%',
			label: {
				width: '100%',
			},

			button: {
				width: '100%',
			},
		},
	},
}))
export default CheckUniversityDialog
