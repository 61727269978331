import styled, { css, useTheme } from 'styled-components'
import Image from 'next/image'
import { useCallback } from 'react'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'

export type OptionType = {
	value: string
	label: string
	image?: string
}

interface SelectProps {
	options: OptionType[]
	onChange: (option: OptionType) => void
	placeholder?: string
	disabled?: boolean
	defaultValue?: string | null
	className?: string
	suffix?: React.ReactNode
	selected?: OptionType
	dropDownChildren?: React.ReactNode
	isOpen?: boolean
	setIsOpen?: (isOpen: boolean) => void
}

export const Select = ({
	options,
	onChange,
	placeholder,
	disabled,
	className,
	suffix,
	selected,
	dropDownChildren,
	isOpen,
	setIsOpen,
}: SelectProps) => {
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}
	const handleOptionClick = useCallback((option: OptionType) => {
		setIsOpen(false)
		onChange(option)
	}, [])

	return (
		<>
			<StyledSelect
				isOpen={isOpen}
				disabled={disabled}
				onClick={toggleDropdown}
				className={`${isOpen ? 'open' : ''} ${className}`}>
				<p>{selected ? selected.label : placeholder}</p>
				{suffix}
				{!isMobile && (
					<ul className="options">
						{options.map((option, idx) => (
							<li
								data-image={option?.image}
								key={`option-${idx}`}
								onClick={() => handleOptionClick(option)}
								className={
									selected?.value === option.value ? 'selected' : undefined
								}>
								<div>
									{option?.image && (
										<Image
											unoptimized={true}
											src={option?.image}
											width={34}
											height={48}
											alt={'book-cover-image'}
										/>
									)}
									{option.label}
								</div>
								<div className={'check-icon'}>✓</div>
							</li>
						))}
						{dropDownChildren}
					</ul>
				)}
			</StyledSelect>
		</>
	)
}

interface StyledSelectProps {
	isOpen?: boolean
	disabled?: boolean
	// value?: string | null
}

export const StyledSelect = styled.div<StyledSelectProps>`
  border: 1px solid ${(props) => props.theme.new.colors.gray200};
  padding: 12px;
  justify-content: space-between;
  color: ${(props) => props.theme.new.colors.gray400};

  display: flex;
  flex: 1;
  cursor: pointer;
  ${(props) => props.isOpen && `border: 1px solid ${props.theme.new.colors.gray900};`}
  position: relative;

  :active {
    border: 1px solid ${(props) => props.theme.new.colors.gray900};
    transition: 0.2s;
  }

  ${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
		`}
  p {
    color: ${(props) => props.theme.new.colors.gray900};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    width: 100%;
    background-color: ${(props) => props.theme.new.colors.white};
    color: ${(props) => props.theme.new.colors.gray900};
    z-index: 1;
    border-radius: 2px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    flex-direction: column;
    ${(props) => props.isOpen && 'display: flex;'};

  }

  .options li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      line-height: 1.5;
    }

    .check-icon {
      visibility: hidden;
      margin-left: 40px;
      font-size: 24px;
      font-weight: 200;
      color: ${(props) => props.theme.new.colors.sol_indigo_500};
    }
  }

  .options li:hover {
    background-color: ${(props) => props.theme.new.colors.sol_gray_0}
  }
;
}

.options li.selected {
  font-weight: 600;

  .check-icon {
    visibility: visible;
  }
}

i {
  ${(props) => props.isOpen && 'transform: rotate(180deg);'}
  transition: 0.2s;
}

@media (max-width: 768px) {
  ul {
    //display: flex;
    //position: inherit;
  }
}
`

export default Select
