import { Button } from '@bookips/solvook-ui-library'
import { Menu, styled, Typography } from '@mui/material'
import Link from 'next/link'
import { EBOOKS_LANDING, EBOOKS_UNIVERSITY_LANDING } from '@/settings/constant'
import { useDropdownMenu } from '@/hooks/useDropdownMenu'
import Icon from '../icon/Icon'
import { ArrowDown, GoodnoteIcon, Sizes } from '../icon/IconList'
import { PropsWithChildren } from 'react'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export const GoodnotesPromotion: React.FC = () => {
	const dropdownList = [
		{
			title: '대학∙수험서∙외국어',
			link: EBOOKS_UNIVERSITY_LANDING,
		},
		{
			title: '중등∙고등',
			link: EBOOKS_LANDING,
		},
	]
	const { handleOpen, handleClose, open, anchorEl } = useDropdownMenu()
	const mixpanelTrigger = (name: string, url?: string) => {
		mixpanelEvent('Home Quick button Clicked', {
			'Button Type': 'Goodnotes',
			'Button Name': name,
			url: url,
		})
	}
	return (
		<>
			<ButtonWithDropdownMenu
				id="goodntes-promotion"
				aria-expanded={open ? 'true' : undefined}
				onClick={(e) => {
					handleOpen(e)
					mixpanelTrigger('굿노트6 eBook')
				}}>
				<Typography
					variant="subtitle2"
					sx={{
						display: 'flex',
						gap: '4px',
						alignItems: 'center',
					}}>
					<Icon icon={GoodnoteIcon} className={'goodnotes-icon'} size={Sizes.large} />
					굿노트6 eBook
				</Typography>
				<Icon
					icon={ArrowDown}
					size={Sizes.xsmall}
					color={'#3B4054'}
					className={'arrow-down'}
				/>
			</ButtonWithDropdownMenu>
			<Menu
				id="goodntes-promotion"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				sx={{
					marginTop: '10px',
				}}>
				<ul>
					{dropdownList.map((item, index) => {
						return (
							<LinkListItem
								key={index + item.title}
								link={item.link}
								onClick={() => mixpanelTrigger(item.title, item.link)}>
								{item.title}
							</LinkListItem>
						)
					})}
				</ul>
			</Menu>
		</>
	)
}
const LinkListItem = ({
	link,
	children,
	onClick,
}: PropsWithChildren<{ link: string; onClick: () => void }>) => {
	return (
		<ListItem>
			<Link onClick={onClick} href={link}>
				{children}
			</Link>
		</ListItem>
	)
}
const ListItem = styled('li')(({ theme }) => ({
	textAlign: 'center',
	...theme.typography.body1,
	'>a': {
		padding: '8px 12px',
		display: 'inline-block',
	},
	'&:hover': {
		backgroundColor: theme.palette.grey[50],
	},
}))

export const ButtonWithDropdownMenu = styled(Button)(({ theme }) => ({
	backgroundColor: '#fff',
	borderRadius: '4px',
	padding: '2px 8px',
	color: theme.palette.text.primary,
	textTransform: 'none',
	...theme.typography.subtitle2,
	'.goodnotes-icon svg': {
		width: '20px',
		height: '20px',
	},
	'.arrow-down svg': {
		marginLeft: '4px',
	},
	'&[aria-expanded="true"]': {
		backgroundColor: theme.palette.dim.gray300['20%'],
		'.arrow-down svg': {
			transform: 'rotate(180deg)',
			transition: 'all 0.3s ease',
		},
	},
	'&:hover': {
		backgroundColor: theme.palette.dim.gray300['20%'],
	},
}))
