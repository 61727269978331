import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Skeleton } from 'antd'
import styled from 'styled-components'

export interface MainPageSkeletonProps {}

const MainPageSkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	//gap: 52px;
	padding: 41px 0;
	@media (max-width: 1200px) {
		//gap: 42px;
	}
	@media (max-width: 768px) {
		padding: 24px 0;
	}
`

export const MainPageSkeleton: React.FC = () => {
	return (
		<MainPageSkeletonWrapper>
			<SelectedBooksSectionSkeleton />
			<MainBannerSkeleton />
			<RankingListSkeleton />
			<MiniBannerSkeleton />
			<ManualCurationSkeleton />
		</MainPageSkeletonWrapper>
	)
}

export const MainBannerSkeleton: React.FC = () => {
	return (
		<StyledSkeleton>
			<MainBannerSkeletonWrapper>
				<Skeleton.Avatar active={true} shape={'square'} />
			</MainBannerSkeletonWrapper>
		</StyledSkeleton>
	)
}

export const MiniBannerSkeleton: React.FC = () => {
	return (
		<StyledSkeleton>
			<MiniBannerSkeletonWrapper>
				<Skeleton.Button active />
				<Skeleton.Button active />
				<Skeleton.Button active />
			</MiniBannerSkeletonWrapper>
		</StyledSkeleton>
	)
}

export const RankingListSkeleton: React.FC = () => {
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	return (
		<StyledSkeleton>
			<RankingSectionSkeletonWrapper>
				<Skeleton.Input />
				<div className={'button-wrapper'}>
					<Skeleton.Button active />
					<Skeleton.Button active />
					<Skeleton.Button active />
				</div>
				<div className={'list-wrapper'}>
					{Array.from({ length: isMobile ? 3 : 12 }).map((item, index) => {
						return (
							<div className={'list-item'} key={`ranking-list-skeleton-${index}`}>
								<Skeleton.Avatar shape={'square'} active />
								<div className={'contents'}>
									<Skeleton.Button
										active
										style={{ width: isMobile ? '120px' : '150px' }}
									/>
									<Skeleton.Button
										style={{ width: isMobile ? '200px' : '260px' }}
										active
									/>
									<Skeleton.Button
										style={{ width: isMobile ? '100px' : '130px' }}
										active
									/>
									<Skeleton.Button
										style={{ width: isMobile ? '80px' : '100px' }}
										active
									/>
								</div>
							</div>
						)
					})}
				</div>
			</RankingSectionSkeletonWrapper>
		</StyledSkeleton>
	)
}

export const SelectedBooksSectionSkeleton: React.FC = () => {
	const active = true
	const size = 'default'
	const { width } = useWindowDimensions()
	const isMobile = width < 769

	return (
		<StyledSkeleton>
			<SelectedBooksSectionSkeletonWrapper>
				<Skeleton.Input
					active
					size={size}
					style={{
						height: isMobile ? '24px' : '32px',
						width: isMobile ? '224px' : '336px',
					}}
				/>

				<div className={'contents'}>
					{Array.from({ length: 8 }).map((item, idx) => {
						return (
							<div key={`selected-books-skeleton-${idx}`}>
								<Skeleton.Avatar
									active={active}
									size={isMobile ? 94 : 120}
									shape={'circle'}
									style={{ marginBottom: isMobile ? '8px' : '16px' }}
								/>
								<Skeleton.Button
									active
									style={{
										height: isMobile ? '18px' : '20px',
										width: isMobile ? '100px' : '136px',
										marginBottom: '4px',
									}}
								/>
								<Skeleton.Button
									active
									style={{
										height: isMobile ? '18px' : '20px',
										width: isMobile ? '80px' : '98px',
									}}
									size={'small'}
								/>
							</div>
						)
					})}
				</div>
			</SelectedBooksSectionSkeletonWrapper>
		</StyledSkeleton>
	)
}
const ManualCurationSkeletonWrapper = styled.div`
	margin-top: 72px;
	.ant-skeleton.ant-skeleton-element .ant-skeleton-input {
		width: 308px;
		height: 32px;
		margin-bottom: 20px;
	}
	.list-wrapper {
		display: flex;
		margin-top: 16px;
		gap: 16px;
		.list-item {
			flex-direction: column;
			width: 420px;
			height: 382px;
			display: flex;
			gap: 20px;
			.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
				width: 224px;
				height: 224px;
				border-radius: 6px;
			}
			.contents {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
					width: 220px;
					height: 26px;

					&:nth-child(2) {
						width: 26px;
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
			width: 150px;
			height: 150px;
		}
	}
`

export const ManualCurationSkeleton: React.FC = () => {
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	return (
		<StyledSkeleton>
			<ManualCurationSkeletonWrapper>
				<Skeleton.Input />
				<div className={'list-wrapper'}>
					{Array.from({ length: 5 }).map((item, index) => {
						return (
							<div className={'list-item'} key={`manual-curation-skeleton-${index}`}>
								<Skeleton.Avatar shape={'square'} active />
								<div className={'contents'}>
									<Skeleton.Button active />
									<Skeleton.Button
										style={{ width: isMobile ? '100px' : '130px' }}
										active
									/>
									<Skeleton.Button
										style={{ width: isMobile ? '80px' : '100px' }}
										active
									/>
								</div>
							</div>
						)
					})}
				</div>
			</ManualCurationSkeletonWrapper>
		</StyledSkeleton>
	)
}
export const SelectedBooksSectionSkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 52px;
	margin-top: 40px;
	.contents {
		display: flex;
		align-items: center;
		gap: 28px;
		max-width: 1200px;
		overflow: hidden;
		height: 198px;
		> div {
			width: 140px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	@media (max-width: 1200px) {
		margin-bottom: 40px;
	}
	@media (max-width: 768px) {
		margin-bottom: 32px;
		margin-top: 24px;
		gap: 16px;
		.contents {
			height: 153px;
			gap: 12px;
		}
	}
`
export const StyledSkeleton = styled.div`
	.ant-skeleton.ant-skeleton-element .ant-skeleton-input,
	.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
		border-radius: 6px;
		//background-color: dddd;
	}
`

const MainBannerSkeletonWrapper = styled.div`
	margin-bottom: 50px;
	@media (max-width: 1200px) {
		margin-bottom: 42px;
	}
	@media (max-width: 768px) {
		margin-bottom: 32px;
	}
	.ant-skeleton.ant-skeleton-element {
		width: 100%;
	}
	.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
		width: 100%;
		height: 180px;
		@media (max-width: 1200px) {
			aspect-ratio: 1100/210;
			max-height: 210px;
			max-width: 1200px;
			width: 100%;
			height: 100%;
		}
		@media (max-width: 768px) {
		}
	}
`
const RankingSectionSkeletonWrapper = styled.div`
	margin-bottom: 72px;
	overflow: hidden;
	.ant-skeleton.ant-skeleton-element .ant-skeleton-input {
		width: 352px;
		height: 32px;
	}
	.button-wrapper {
		display: flex;
		gap: 12px;
		margin-top: 20px;
		.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
			width: 80px;
			height: 40px;
		}
	}
	.list-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		/* row-gap: 28px; */
		/* column-gap: 36px; */
		gap: 36px 32px;
		margin-top: 20px;
		.list-item {
			width: 376px;
			height: 172px;
			display: flex;
			overflow: hidden;
			gap: 16px;
			.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
				border-radius: 6px;
				width: 136px;
				height: 100%;
			}
			.contents {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
					height: 32px;
				}
			}
		}
		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
		}
	}
	@media (max-width: 768px) {
		margin-bottom: 38px;
		.button-wrapper {
			margin-top: 16px;
			.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
				height: 30px;
				width: 68px;
			}
		}
		.list-wrapper {
			column-gap: 20px;
			row-gap: 20px;
			margin-top: 16px;
			.list-item {
				width: 320px;
				height: 126px;
				gap: 12px;
				.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
					width: 108px;
				}
				.contents {
					.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
						height: 22px;
					}
				}
			}
		}
	}
`
const MiniBannerSkeletonWrapper = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	flex: 1 1 auto;
	margin-bottom: 72px;
	.ant-skeleton.ant-skeleton-element {
		width: 100%;
		flex: 1 1 auto;
	}
	.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
		width: 100%;
		height: 104px;
		display: flex;
		flex: 1 1 auto;
	}
	@media (max-width: 768px) {
		margin-bottom: 38px;
		overflow: hidden;
		.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
			width: 280px;
			height: 74px;
			display: flex;
		}
	}
`
