import React from 'react'
import styled, { useTheme } from 'styled-components'
import Sheet from 'react-modal-sheet'

import Icon from '@/components/icon/Icon'
import { Close, Plus, Sizes } from '@/components/icon/IconList'
import { StyledSelect } from '@/components/common/Select'
import { itemDTO } from 'src/api/main'
import { Button } from 'antd'
import Image from 'next/image'
import { SelectedBooksDropDownWrapper } from './SelectedBookDropDown'
import { CloseButton } from './UserSourceStep'

export interface MobileSelectedBooksSheetProps {
	isDropdownOpen: boolean
	setIsDropdownOpen: (isOpen: boolean) => void
	selectBookList: itemDTO[]
	setSelectedBook: (book: itemDTO) => void
	selectedBook: itemDTO
	openSelectBooksModal: () => void
}

export const SelectedBookDropDownMobile: React.FC<MobileSelectedBooksSheetProps> = ({
	isDropdownOpen,
	setIsDropdownOpen,
	selectBookList,
	openSelectBooksModal,
	selectedBook,
	setSelectedBook,
}) => {
	const theme = useTheme()
	return (
		<>
			<Sheet
				isOpen={isDropdownOpen}
				onClose={() => setIsDropdownOpen(false)}
				className={'bottom-sheet-bg'}>
				<Sheet.Container>
					<Sheet.Content>
						<MobileSelectedBooksSheetWrapper>
							<CloseButton>
								<Icon
									onClick={() => setIsDropdownOpen(false)}
									icon={Close}
									size={Sizes.xmedium}
									color={theme.new.colors.sol_gray_700}
								/>
							</CloseButton>
							<SelectedBooksDropDownWrapper>
								<StyledSelect className={'selectBooks-dropdown'}>
									<div>
										<ul className="options">
											{selectBookList.map((option, idx) => (
												<li
													data-image={option?.imageUrl}
													key={`option-${idx}`}
													onClick={() => {
														setSelectedBook(option)
														setIsDropdownOpen(false)
													}}
													className={
														option.id === selectedBook?.id
															? 'selected'
															: ''
													}>
													<div>
														{option?.imageUrl && (
															<Image
																unoptimized={true}
																src={option?.imageUrl}
																width={34}
																height={48}
																alt={'book-cover-image'}
															/>
														)}
														{option.title}
													</div>
													<div className={'check-icon'}>✓</div>
												</li>
											))}
											<Button
												className={'add-books-button'}
												type={'primary'}
												onClick={() => {
													setIsDropdownOpen(false)
													openSelectBooksModal()
												}}>
												<Icon
													icon={Plus}
													size={Sizes.xmedium}
													color={theme.new.colors.sol_indigo_600}
												/>
												추가하기
											</Button>
										</ul>
									</div>
								</StyledSelect>
							</SelectedBooksDropDownWrapper>
						</MobileSelectedBooksSheetWrapper>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
		</>
	)
}
const MobileSelectedBooksSheetWrapper = styled.div`
	margin-top: 40px;
	> div {
		padding: 0;
	}
	.selectBooks-dropdown {
		overflow: auto;
		width: 100%;
		padding: 0;
		margin: 0;
		//margin-top: 40px;
		> div {
			width: 100%;
		}
		ul {
			max-height: calc(100vh - 100px);
			max-width: unset;
			padding: 12px 12px 27px;
			box-shadow: none;
			display: flex;
			position: inherit;
			&.options {
				width: 100%;
				li {
					max-width: unset;
					padding: 8px;
					img {
						width: 34px;
						height: 48px;
					}
				}
			}
		}
	}
`
