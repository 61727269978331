import React from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'
import { itemDTO } from 'src/api/main'
import styled from 'styled-components'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper.min.css'

import { Subjects } from 'src/api/product'
import SelectBooksBanner from './SelectedBookBanner'
import { SelectedBookList } from './SelectedBookList'

export interface FavBooksViewProps {
	openSelectBooksModal: () => void
	selectBookList: itemDTO[]
	selectedBook: itemDTO
	setSelectedBook: (book: itemDTO) => void
	bottom: (node?: Element) => void
	setModalType: (ModalType) => void
	subject: Subjects
}

export const SelectedBooksSection: React.FC<FavBooksViewProps> = ({
	openSelectBooksModal,
	selectBookList,
	setSelectedBook,
	selectedBook,
	bottom,
	setModalType,
	subject,
}) => {
	const hasBookList = selectBookList?.length > 0
	if (!hasBookList)
		return (
			<SelectBooksArea className={'banner'}>
				<SelectBooksBanner openSelectBooksModal={openSelectBooksModal} subject={subject} />
				<div ref={bottom} />
			</SelectBooksArea>
		)
	return (
		<SelectBooksArea className={'carousel'}>
			<SelectedBookList
				openSelectBooksModal={openSelectBooksModal}
				selectBookList={selectBookList}
				selectedBook={selectedBook}
				setSelectedBook={setSelectedBook}
				bottom={bottom}
				setModalType={setModalType}
				subject={subject}
			/>
			<div ref={bottom} />
		</SelectBooksArea>
	)
}

const SelectBooksArea = styled.section`
	width: 100vw;
	margin-left: calc(50% - 50vw);

	&.banner {
		padding-top: 62px;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
	}

	&.carousel {
		padding-top: 40px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_0};
		border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	}

	@media (max-width: 1200px) {
		width: calc(100% + 80px);
		margin: 0 -40px;
		&.banner {
			overflow: hidden;
			padding: 62px 40px 0 40px;
			background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
		}

		&.carousel {
			padding: 40px 40px 0 40px;
			background-color: ${(props) => props.theme.new.colors.sol_gray_0};
			border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		}
	}
	@media (max-width: 768px) {
		width: calc(100% + 32px);
		margin: 0 -16px;
		&.banner {
			padding: 32px 16px;
			background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
			position: relative;
		}

		&.carousel {
			padding: 24px 16px 0 16px;
			background-color: ${(props) => props.theme.new.colors.sol_gray_0};
			border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		}
	}
`
